import React from "react";
import Header from "../components/header/header.js";
import BookingEngineIcon from "../assets/img/svg/booking-engine.svg";

const BookingEngine = () => {
  return (
    <>
      <Header
        title="Booking Engine"
        description={`Ti consente di gestire in modo rapido ed automatico tutti i portali di distribuzione online da un solo back-office.`}
        categoryIcon={{ icon: BookingEngineIcon, alt: "Booking Engine Banner" }}
        metaDescription="Ti consente di gestire in modo rapido ed automatico tutti i portali di distribuzione online da un solo back-office."
      />
      <div className="container">
        <div className="columns">
          <div className="column is-7">
            <span className="green bold">
              È uno strumento potente e con varie funzionalità, tra le quali
              spicca quella di prenotare in modo veloce e sicuro.
            </span>
            <p className="description">
              <br />
              Sono personalizzabili anche i testi del front-end, le notifiche e
              i messaggi. Gli utenti che si collegano al sito web della tua
              struttura possono consultare in tempo reale, la disponibilità
              delle camere, le tariffe, e successivamente prenotare in completa
              autonomia il soggiorno, fornendo i dati della propria carta di
              credito a garanzia. È un sistema di prenotazione online che
              trasforma il sito web in un canale di vendita diretta per
              fidelizzare i clienti. Inoltre, ti consente di ridurre i tempi di
              management e sarà possibile lavorare anche con uno staff ridotto,
              senza che l’efficienza venga compromessa! Con Book@Me potrai
              godere di uno strumento estremamente performante e intuitivo, in
              grado di supportare l'utente in ogni fase dell'acquisto, grazie
              anche alla sua fruibilità, essendo accessibile da tutti i
              dispositivi di prenotazione, quali tablet, mobile, notebook e
              desktop.
            </p>
          </div>
        </div>
      </div>

      <div className="section" />
      <div className="columns">
        <div className="column is-half is-offset-one-quarter">
          <h3>Ti sei mai chiesto</h3>
        </div>
      </div>
      <div className="section" />
      <div className="container">
        <div className="columns">
          <div className="column">
            <span className="green">
              Perché prenotare direttamente dal sito web dell'Hotel?
            </span>
          </div>
          <div className="column">
            <p className="description">
              Prenotazione immediata
              <br /> Sicurezza
              <br />
              Tariffe e disponibilità aggiornate
              <br />
              Miglior tariffa garantita
              <br />
              <br />
            </p>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <span className="green">
              Perché un Hotel dovrebbe utilizzare un booking engine?
            </span>
          </div>
          <div className="column">
            <p className="description">
              Vendita diretta a zero commissioni
              <br />
              Fidelizzazione dei clienti
              <br />
              Riduzione dei tempi di lavoro
              <br />
              Aumento delle prenotazioni
              <br />
              Monitoraggio dalle OTA
              <br />
              <br />
            </p>
          </div>
        </div>
      </div>
      <div className="section" />
    </>
  );
};

export default BookingEngine;
